import { Route, Routes } from 'react-router-dom';
import ToolSelection from 'components/locations/Home/ToolSelection';
import Oems from 'components/Oem/Oems';
import AuthorizedElement from 'routes/AuthorizedElement';
import RefreshPublisher from 'components/locations/RefreshManager/RefreshPublisher/RefreshPublisher';
import ImportJobDetails from './ImportManager/ImportJobDetails';
import ImportManagerDashboard from './ImportManager/Dashboard';
import ImportHistory from './ImportManager/ImportHistory';
import IngestionManager from './IngestionManager/IngestionManager';
import VehicleDataManager from './VehicleDataManager/VehicleDataManager';
import { OemId } from 'helpers/OemId';
import fetchCounts from 'helpers/FetchCountsHelper';

const RefreshManagerRoutes = () => {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <ToolSelection
                        tools={[
                            {
                                path: '/refreshmanager/refreshpublisher',
                                text: 'Refresh Publisher',
                                icon: 'sink',
                                tileDescription: 'Tool for managing refreshed procedure publishing in our system',
                                access: 'tool.refreshManager.refreshPublisher',
                            },
                            {
                                path: '/refreshmanager/import-manager',
                                text: 'Import Manager',
                                icon: 'file-import',
                                tileDescription:
                                    'Tool for viewing refresh status information and kicking off the importer',
                                access: 'tool.refreshManager.importManager',
                            },
                            {
                                path: '/refreshmanager/ingestion-manager',
                                text: 'Ingestion Manager',
                                icon: 'fish-fins',
                                tileDescription: 'Tool for TODO',
                                access: 'tool.refreshManager.importManager',
                            },
                            {
                                path: '/refreshmanager/vehicle-data-manager',
                                text: 'Vehicle Data Manager',
                                icon: 'car',
                                tileDescription: 'Tool for viewing Vehicle Data ingestion status and history',
                                access: 'tool.refreshManager.importManager',
                            },
                        ]}
                    />
                }
            />
            <Route
                path="/refreshpublisher"
                element={
                    <AuthorizedElement resource={'tool.refreshManager.refreshPublisher'}>
                        <Oems />
                    </AuthorizedElement>
                }
            />
            <Route
                path="/refreshpublisher/:oemId"
                element={
                    <AuthorizedElement resource={'tool.refreshManager.refreshPublisher'}>
                        <RefreshPublisher />
                    </AuthorizedElement>
                }
            />
            <Route
                path="/import-manager"
                element={
                    <AuthorizedElement resource={'tool.refreshManager.importManager'}>
                        <ImportManagerDashboard />
                    </AuthorizedElement>
                }
            />
            <Route
                path="/import-manager/:oemId"
                element={
                    <AuthorizedElement resource={'tool.refreshManager.importManager'}>
                        <ImportHistory />
                    </AuthorizedElement>
                }
            />
            <Route
                path="/import-manager/:oemId/:importJobId"
                element={
                    <AuthorizedElement resource={'tool.refreshManager.importManager'}>
                        <ImportJobDetails />
                    </AuthorizedElement>
                }
            />
            <Route
                path="/ingestion-manager"
                element={
                    <AuthorizedElement resource={'tool.refreshManager.ingestionManager'}>
                        <Oems
                            mode={{ isInIngestionManager: true }}
                            oemsConfiguration={[OemId.Ford, OemId.GMC, OemId.Hyundai, OemId.Genesis]}
                            fetchCounts={fetchCounts}
                            context="ingestionManager"
                        />
                    </AuthorizedElement>
                }
            />
            <Route
                path="/ingestion-manager/:oemId"
                element={
                    <AuthorizedElement resource={'tool.refreshManager.ingestionManager'}>
                        <IngestionManager />
                    </AuthorizedElement>
                }
            />
            <Route
                path="/vehicle-data-manager"
                element={
                    <AuthorizedElement resource={'tool.refreshManager.vehicleDataManager'}>
                        <VehicleDataManager />
                    </AuthorizedElement>
                }
            />
        </Routes>
    );
};

export default RefreshManagerRoutes;
