import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const settingsFromDataTemplate = dataTemplate => {
    return dataTemplate.map(dt => {
        return { id: dt.id, text: dt.displayName, isHidden: false, width: 'initial' };
    });
};

const mergeSettings = (settings, savedSettings) => {
    let i;
    const indexed = settings.reduce((acc, p) => {
        return { ...acc, [p.id]: (i = savedSettings.findIndex(s => s.id == p.id)) >= 0 ? i : settings.length };
    }, {});
    return settings
        .map(p => {
            return { ...p, ...savedSettings.find(s => s.id == p.id) };
        })
        .sort((a, b) => indexed[a.id] - indexed[b.id]);
};

const readColumnSettings = (oemId, localStorageName, dataTemplate) => {
    const settings = settingsFromDataTemplate(dataTemplate);
    const storageObject = localStorage.getItem(`${oemId}-${localStorageName}`);
    if (storageObject) {
        return mergeSettings(settings, JSON.parse(storageObject)); // TODO sort accoring to sort from settings
    }

    return settings;
};

const saveColumnSettings = (oemId, localStorageName, columnSettings) => {
    localStorage.setItem(`${oemId}-${localStorageName}`, JSON.stringify(columnSettings));
};

const useColumnSettings = (localStorageName, dataTemplate) => {
    const { oemId } = useParams();
    const [columnSettings, setColumnSettings] = useState(() =>
        readColumnSettings(oemId, localStorageName, dataTemplate).sort((a, b) => a.isHidden - b.isHidden)
    );

    useEffect(() => {
        setColumnSettings(
            readColumnSettings(oemId, localStorageName, dataTemplate).sort((a, b) => a.isHidden - b.isHidden)
        );
    }, [oemId, localStorageName, dataTemplate]);

    const [showColumnSettings, setShowColumnSettings] = useState(false);

    const handleSaveColumnSettings = columnSettings => {
        columnSettings.sort((a, b) => a.isHidden - b.isHidden);
        saveColumnSettings(oemId, localStorageName, columnSettings);
        setColumnSettings(columnSettings);
        setShowColumnSettings(false);
    };

    return { columnSettings, handleSaveColumnSettings, showColumnSettings, setShowColumnSettings };
};

export default useColumnSettings;
