import { fetchWithAuthHeader } from './AuthUtils';
import apiConfig from './config/ApiConfig';
import { OemId } from 'helpers/OemId';

const ITEMS_PER_ODATA_QUERY = 100;

export const requestOems = async () => {
    const url = 'api/VehicleInfo/GetOemsAsync';
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not retrieve oems', { status: response.status });
    return response.json();
};

export const requestOemHasVehicles = async (oemId, filterActive = false) => {
    const url = `api/VehicleInfo/Oem/${oemId}/HasVehicles?active=${filterActive}`;
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not retrieve oems', { status: response.status });
    return response.json();
};

export const requestVehicle = async vehicleId => {
    const url = `api/VehicleInfo/GetVehicleById?vehicleId=${vehicleId}`;
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error(`Could not retrieve vehicle for vehicleId ${vehicleId}`);
    return response.json();
};

export const requestVehiclePublish = async (vehicleId, active) => {
    const url = `api/VehicleInfo/publish/${vehicleId}?active=${active}`;
    let response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({}), //empty post body
    });

    if (!response.ok) throw new Error(`Failed to publish vehicle ${vehicleId}`);
};

export const requestVehiclePublishHistory = async (vehicleId, pageSize, page) => {
    let url = `api/VehicleInfo/GetVehiclePublishes?vehicleId=${vehicleId}`;

    if (pageSize) {
        url = `${url}&pageSize=${pageSize}`;
    }

    if (page) {
        url = `${url}&page=${page}`;
    }

    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not retrieve vehicle publish history');
    return response.json();
};

export const requestLastVehiclePublishHistory = async vehicleId => {
    let url = `api/VehicleInfo/GetLastVehiclePublish?vehicleId=${vehicleId}`;

    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not retrieve vehicle publish history');
    if (response.status === 200) return response.json();
    else return null;
};

/**
 * Retrieve list of groups (previously regions) for a vehicle using vehicleId
 * @deprecated: logic no longer used, please use requestRegions()
 * @param {number} vehicleId vehicle id
 * @returns a list of regions or undefined if not found (caller needs to deal with this situation)
 * @throws an error if the request failed
 */
export const requestGetVehicleRegions = async vehicleId => {
    const url = `api/VehicleInfo/GetVehicleRegions?vehicleId=${vehicleId}`;
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not retrieve vehicle regions');
    if (response.status === 204) return undefined;
    return await response.json();
};

export const requestGetVehicleTypesWithRegions = async () => {
    const url = 'api/VehicleInfo/GetVehicleTypesWithRegions';
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not retrieve vehicle types with regions');
    return response.json();
};

export const requestVehicleByOem = async (oemId, modelId = null) => {
    let url = `api/VehicleInfo/GetVehiclesByOem?oemId=${oemId}`;

    //qualify by by model if filtering down to specified model
    if (modelId) url += `&modelId=${modelId}`;

    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not retrieve vehicles by oemId');
    return response.json();
};

const setVehicleLocales = async vehicles => {
    let uniqueLocales = new Map();

    vehicles.forEach(vehicle => {
        vehicle.vehicleLocales.forEach(locale => {
            uniqueLocales.set(locale.localeId, '');
        });
    });

    for (const [key] of uniqueLocales) {
        let data = await requestLocaleDetailsOdata(key);
        uniqueLocales.set(key, data.country);
    }

    vehicles.forEach(vehicle => {
        vehicle.locales = (vehicle.vehicleLocales || []).map(
            locale => uniqueLocales.get(locale.localeId) || '' // Fallback to empty string if not found
        );
        vehicle.locales = [...new Set(vehicle.locales)]; // Remove duplicates (ex: CA_EN, CA_US)
        vehicle.locales.sort(); // Insure that Locales are always displayed in the same order
    });
};

const generateVehicleByOemOdataUrl = (oemId, modelId, currentIndex) => {
    const baseUrl = `api/VehicleDetails/odata/vehicle`;

    const queryParams = new URLSearchParams({
        $skip: currentIndex,
        $top: ITEMS_PER_ODATA_QUERY,
        $count: 'true',
        $expand: 'oem,model,trim,visualModel,vehicleLocales,year,oemModels',
        $filter: `oemId eq ${oemId}`,
    });

    // Append modelId conditionally
    if (modelId) {
        queryParams.set('$filter', `${queryParams.get('$filter')} and modelId eq ${modelId}`);
    }

    return `${baseUrl}?${queryParams.toString()}`;
};

export const requestVehicleByOemOdata = async (oemId, modelId = null, loadLocaleInfo = true) => {
    let vehicles = [];
    let index = 0;

    try {
        let odataCount = 0;

        do {
            const response = await fetchWithAuthHeader(generateVehicleByOemOdataUrl(oemId, modelId, index));

            if (!response.ok) {
                throw new Error(`Could not retrieve vehicles by oemId`);
            }

            const data = await response.json();

            // Check if odata returned 'value' property in the response
            if (data.value && Array.isArray(data.value)) {
                vehicles.push(...data.value);
            }

            // Update odataCount for the next iteration
            odataCount = data['@odata.count'];
            index += ITEMS_PER_ODATA_QUERY;
        } while (index < odataCount);
    } catch (error) {
        console.error('Error fetching vehicles:', error);
        throw error; // Re-throwing to maintain error propagation
    }

    if (loadLocaleInfo) {
        await setVehicleLocales(vehicles);
    }

    let _settings = await apiConfig.get();

    // Make sure to prefix the model's url with the current configuration's modelBaseUrl
    vehicles.forEach(v => {
        if (!v.visualModel) return;

        v.visualModel.path = _settings.modelBaseUrl + v.visualModel.path;
    });

    return vehicles;
};

export const requestLocaleDetailsOdata = async localeId => {
    let url = `api/VehicleDetails/odata/Locale?$select=country,language&$filter=localeId eq ${localeId}`;
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not resolve locale id');
    const data = await response.json();

    // Do this because odata will always return an array although we are always expecting a single element
    // return null if none are found. Maybe throw an error instead??
    return data.value && data.value.length > 0 ? data.value[0] : null;
};

export const requestSearchVehicleColors = async colorName => {
    const url = `api/VehicleInfo/ExteriorColor/SearchExteriorColorsByName/${colorName}`;
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not find exterior color');
    return response.json();
};

export const requestCreateNewColor = async (colorName, colorHex) => {
    const url = `api/VehicleInfo/ExteriorColor/CreateNewColor/${colorName}/${colorHex}`;
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not create color');
    return response.json();
};

export const requestMapExteriorColorToVehicle = async (vehicleId, externalColorId) => {
    const url = `api/VehicleInfo/ExteriorColor/MapExteriorColorToVehicle/${vehicleId}/${externalColorId}`;
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not map external color');
};

export const requestUnMapExteriorColorToVehicle = async (vehicleId, externalColorId) => {
    const url = `api/VehicleInfo/${vehicleId}/ExteriorColor/${externalColorId}`;
    let response = await fetchWithAuthHeader(url, {
        method: 'DELETE',
    });
    if (!response.ok) throw new Error('Could not map external color');
};

export const requestUpdateExteriorColor = async exteriorColor => {
    const url = `api/VehicleInfo/ExteriorColor/UpdateExteriorColor/${exteriorColor.exteriorColorId}`;
    let response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            exteriorColorId: exteriorColor.exteriorColorId,
            exteriorColorName: exteriorColor.exteriorColorName,
            exteriorColorHex: exteriorColor.exteriorColorHex,
        }),
    });

    if (!response.ok) throw new Error('Could not update exterior color');
};

export const requestVehicleByYMM = async (yearId, oemId, modelId) => {
    const url = `api/VehicleInfo/Vehicle/GetVehicleByYMM/${yearId}/${oemId}/${modelId}`;
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not get vehicle');
    return await response.json();
};

/**
 * Retrieve all groups available
 * @returns a list of regions if found
 * @returns undefined if not found (caller needs to deal with this situation)
 * @throws an error if the request failed
 */
export const requestRegions = async () => {
    const url = 'api/VehicleInfo/Region';
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not get regions');
    if (response.status === 204) return undefined;
    return await response.json();
};

export const requestYears = async () => {
    const url = 'api/VehicleInfo/Years';
    const response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not get years');
    return await response.json();
};

export const requestModelsForOem = async oemId => {
    const url = `api/VehicleInfo/Oem/${oemId}/models`;
    const response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error('Could not get models');
    return await response.json();
};

export const requestPatchVehicle = async (vehicleId, isTrimLevelFilteringEnabled) => {
    const url = 'api/VehicleInfo/PatchVehicle';
    let response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            vehicleId: vehicleId,
            isTrimLevelFilteringEnabled: isTrimLevelFilteringEnabled,
        }),
    });

    if (!response.ok) throw new Error('Could not update vehicle record');
};

export const requestFindVehicleVisualModel = async (modelName, years = []) => {
    let url = `api/VehicleInfo/FindVehicleVisualModel?modelName=${modelName}`;

    //expect the list of years are valid numbers
    const yearIds = years.filter(element => typeof element === 'number');

    let response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            modelName,
            years: yearIds,
        }),
    });

    if (!response.ok) throw new Error(`Failed to find vehicle visual models for ${modelName}`);
    return await response.json();
};

export const requestFindVehicleOemModel = async oemId => {
    const url = `api/VehicleDetails/odata/OemModel?$filter=oemId eq ${oemId}`;
    const response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error(`Failed to find vehicle OEM models for OemId ${oemId}`);
    const result = await await response.json();
    return result.value;
};

/**
 * To bulk update vehicle. Set what prop to update in toUpdate param
 * @param toUpdate set what props to update
 */
export const requestUpdateVehicles = async (vehicleIds, oemId, toUpdate) => {
    const url = 'api/VehicleDetails/odata/vehicle/update';
    const body = { vehicleIds, oemId };
    if (toUpdate.isTrimLevelFilteringEnabled !== undefined) {
        body.isTrimLevelFilteringEnabled = toUpdate.isTrimLevelFilteringEnabled;
    }
    if (toUpdate.visualModelId !== undefined) {
        body.visualModelId = toUpdate.visualModelId ?? 0;
    }
    if (toUpdate.oemModelIds !== undefined) {
        body.oemModelIds = toUpdate.oemModelIds ?? [];
    }
    if (toUpdate.isDisplayingTagsEnabled !== undefined) {
        body.isDisplayingTagsEnabled = toUpdate.isDisplayingTagsEnabled;
    }

    const payload = JSON.stringify(body);
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: payload,
    });

    if (!response.ok) {
        throw new Error(`Failed to bulk update vehicle ${payload}`);
    }

    const result = await response.json();
    return result;
};

/**
 * To update displaying tags bit of a vehicle
 */
export const requestUpdateVehicleDisplayingTags = async (vehicleId, isDisplayingTagsEnabled) => {
    const url = `api/VehicleInfo/${vehicleId}/IsDisplayingTagsEnabled`;
    const body = JSON.stringify({ vehicleId: vehicleId, isDisplayingTagsEnabled: isDisplayingTagsEnabled });

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: body,
    });

    if (!response.ok) {
        throw new Error(`Failed to update vehicle displaying tags bit ${body}`);
    }
    return await response.json();
};

export const requestGetOemRefreshSchedules = async () => {
    const url = 'api/VehicleInfo/OemRefreshSchedules';
    const response = await fetchWithAuthHeader(url);
    const content = await response.json();
    if (!response.ok) {
        throw new Error(`${content.status} - ${content.title}`);
    }
    return content;
};

export const requestSkipNextRefreshById = async id => {
    const url = `api/VehicleInfo/OemRefreshSchedules/${id}/SkipNextRefresh`;

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });

    const content = await response.json();
    if (!response.ok) {
        throw new Error(`${content.status} - ${content.title}`);
    }
    return content;
};

export const requestUpdateRefreshScheduleById = async (id, isActive) => {
    const url = 'api/VehicleInfo/UpdateRefreshSchedules';
    const body = JSON.stringify({ id, isActive });

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: body,
    });

    const content = await response.json();
    if (!response.ok) {
        throw new Error(`${content.status} - ${content.title}`);
    }
    return content;
};

export const requestVehiclePublishExceptionCategories = async () => {
    const url = `api/VehicleInfo/VehiclePublishExceptionCategory`;
    let response = await fetchWithAuthHeader(url);
    if (!response.ok) throw new Error(`Could not retrieve vehicle publish Exception categories`);
    return response.json();
};

export const requestVehiclePublishException = async vehicleId => {
    const url = `api/VehicleInfo/VehiclePublishException?vehicleId=${vehicleId}`;
    let response = await fetchWithAuthHeader(url);
    if (response.ok && response.status == 204) return null;
    else if (!response.ok) {
        if (response.status == 404) return null;
        throw new Error(`Could not retrieve vehicle publish Exception data`);
    }
    return response.json();
};

export const requestUpdateVehiclePublishException = async vehicleObj => {
    const url = 'api/VehicleInfo/VehiclePublishException/Update';
    const body = JSON.stringify(vehicleObj);

    const response = await fetchWithAuthHeader(url, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: body,
    });

    const content = await response.json();
    if (!response.ok) {
        throw new Error(`${content.status} - ${content.title}`);
    }
    return content;
};

export const requestAddVehiclePublishException = async vehicleObj => {
    const url = 'api/VehicleInfo/VehiclePublishException/Add';
    const body = JSON.stringify(vehicleObj);

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: body,
    });
    const content = await response.json();
    if (!response.ok) {
        throw new Error(`${content.status} - ${content.title}`);
    }
    return content;
};

export const requestNewNVDVehicleCounts = async () => {
    const counts = await Promise.all(
        Object.values(OemId)
            .filter(value => typeof value === 'number') // Keep only numbers due to reverse mappings
            .map(async oemId => {
                const count = await getUnreviewedVehicleCount(oemId);
                return { oemId: oemId, count };
            })
    );

    const total = counts.reduce((sum, { count }) => sum + count, 0);

    const result = counts.reduce((acc, { oemId, count }) => {
        acc[oemId] = { newVehicleCount: count };
        return acc;
    }, {});

    result.total = total;

    return result;
};

const generateRequestNewVehicleCountsUrl = oemId => {
    const baseUrl = `api/VehicleDetails/odata/vehicle`;

    const queryParams = new URLSearchParams({
        $top: 0,
        $count: 'true',
        $filter: `isReviewed eq false and oemId eq ${oemId}`,
    });

    return `${baseUrl}?${queryParams.toString()}`;
};

export const getUnreviewedVehicleCount = async oemId => {
    const response = await fetchWithAuthHeader(generateRequestNewVehicleCountsUrl(oemId));

    if (!response.ok) {
        throw new Error(`Could not retrieve vehicles by oemId`);
    }

    const data = await response.json();

    return data['@odata.count'];
};

export const requestUpdateIsVehicleReviewed = async (vehicleId, isReviewed) => {
    // Switch this to use the Proxy instead of hitting

    const url = `api/VehicleDetails/Vehicle/${vehicleId}/IsReviewed?api-version=2`;
    const body = JSON.stringify({ vehicleId: vehicleId, isReviewed: isReviewed });

    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: body,
    });

    if (!response.ok) {
        throw new Error(`Failed to update vehicle isReviewed ${body}`);
    }
    return await response.json();
};
