import { useEffect, useState, useContext, useRef } from 'react';
import { requestImports, requestImportRecord } from 'api/VehicleData';
import { ToastContext } from 'components/ToastProvider';

function convertMillisecondDurationToTime(ms) {
    const hours = Math.floor(ms / (1000 * 60 * 60));
    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((ms % (1000 * 60)) / 1000);
    const milliseconds = ms % 1000;

    return { hours, minutes, seconds, milliseconds };
}

const useVehicleDataManager = () => {
    const [importRecords, setImportRecords] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const importRecordsRef = useRef(importRecords); // Use ref to keep track of the import records between renders
    const { showToast } = useContext(ToastContext);
    const durationIntervalId = useRef(null);
    const apiRefreshIntervalId = useRef(null);

    /**
     * This function will calculate the import duration for the records that are still running
     * @param {*} records The current set of import records
     * @returns [boolean, Array] A tuple containing a boolean indicating if the records were updated and the updated records
     */
    const calculateImportDurations = records => {
        let areRecordsUpdating = false;

        records = records.map(record => {
            if (record.success === null) {
                const timeDifference = new Date() - new Date(record.importDate);
                const time = convertMillisecondDurationToTime(timeDifference);

                let importDuration = `PT${time.hours}H${time.minutes}M${time.seconds}.${time.milliseconds}S`;

                areRecordsUpdating = true;
                return {
                    ...record,
                    importDuration: importDuration,
                };
            }
            return record;
        });
        return [areRecordsUpdating, records];
    };

    /**
     * This function will sync the records with the API to get the latest status of records that are still running
     * @param {*} records The current set of import records
     * @returns [boolean, Array] A tuple containing a boolean indicating if the records were updated and the updated records
     */
    const syncRecordsWithAPI = async records => {
        let hasRecordsChanged = false;

        const updatedRecords = await Promise.all(
            records.map(async record => {
                if (record.success !== null) return record;

                const response = await requestImportRecord(record.importHistoryId);

                // If the record is not updated
                if (response.success === null) return record;

                hasRecordsChanged = true;

                const { success, filesImported, importDuration } = response;

                return {
                    ...record,
                    success,
                    filesImported,
                    importDuration,
                };
            })
        );

        return [hasRecordsChanged, updatedRecords];
    };

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);

                let records = await requestImports();
                records.sort((a, b) => b.importDate.localeCompare(a.importDate));

                [, records] = calculateImportDurations(records);

                setImportRecords(records);
            } catch (error) {
                showToast(error);
            } finally {
                setIsLoading(false);
            }
        })();
    }, [showToast]);

    useEffect(() => {
        importRecordsRef.current = importRecords;
    }, [importRecords]);

    // Update the records import duration every second
    useEffect(() => {
        if (durationIntervalId.current) clearInterval(durationIntervalId.current);

        durationIntervalId.current = setInterval(() => {
            if (isLoading) return;

            let areRecordsUpdating;
            let updatedRecords = [...importRecordsRef.current];

            [areRecordsUpdating, updatedRecords] = calculateImportDurations(updatedRecords);

            // If no records were updated, we can stop the interval and skip the setImportRecords
            if (!areRecordsUpdating) {
                clearInterval(durationIntervalId.current);
                clearInterval(apiRefreshIntervalId.current);
                return;
            }

            setImportRecords(updatedRecords);
        }, 1000);

        return () => clearInterval(durationIntervalId); // Clean up
    }, [isLoading]);

    // Update the record status every ten second
    useEffect(() => {
        if (apiRefreshIntervalId.current) clearInterval(apiRefreshIntervalId.current);

        apiRefreshIntervalId.current = setInterval(async () => {
            if (isLoading) return;

            let hasRecordsChanged;
            let updatedRecords = [...importRecordsRef.current];

            [hasRecordsChanged, updatedRecords] = await syncRecordsWithAPI(updatedRecords);

            // If no records were updated, we can stop the interval and skip the setImportRecords
            if (!hasRecordsChanged) {
                return;
            }

            setImportRecords(updatedRecords);
        }, 10000);

        return () => clearInterval(apiRefreshIntervalId.current); // Clean up
    }, [isLoading]);

    return {
        importRecords,
        isLoading,
    };
};

export default useVehicleDataManager;
