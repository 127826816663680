import React, { useCallback, useMemo, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TableFiltersAddEdit from './TableFiltersAddEdit';
import TableFiltersItem from './TableFiltersItem';
import { formatConfigurations, addConfigurationParams } from './tableFilterHelpers';

const TableFilters = ({ filters, setFilters, configuration, onReloadButtonClick = null }) => {
    const modal = useRef();

    const filterConfigurations = useMemo(() => formatConfigurations(configuration), [configuration]);

    const addConfigurationParameters = useCallback(
        filter => addConfigurationParams(filter, filterConfigurations.map[filter.id]),
        [filterConfigurations.map]
    );

    const disabledFilters = useMemo(() => {
        const filtersInstances = {};
        filters.forEach(f => (filtersInstances[f.id] ? filtersInstances[f.id]++ : (filtersInstances[f.id] = 1)));
        return filters
            .filter(
                f =>
                    !filterConfigurations.map[f.id] ||
                    filtersInstances[f.id] >= (filterConfigurations.map[f.id].allowInstances || 1)
            )
            .map(f => f.id);
    }, [filters, filterConfigurations.map]);

    const addEditFilter = useCallback(
        filter => {
            setFilters(current => {
                const index = current.findIndex(f => f.key === filter.key);
                return index > -1
                    ? ((current[index] = addConfigurationParameters(filter)), [...current])
                    : [...current, addConfigurationParameters(filter)];
            });
        },
        [addConfigurationParameters, setFilters]
    );

    const removeFilter = useCallback(
        index => {
            setFilters(current => [...current.filter((f, i) => i !== index)]);
        },
        [setFilters]
    );

    const openEditFilter = useCallback((filter, index) => modal.current.open(filter, index), [modal]);

    return (
        <div className="table-filters d-flex flex-row mt-3 bg-light">
            {onReloadButtonClick ? (
                <div className="control-btn me-1">
                    <button type="button" className="btn btn-light border" title="Reload" onClick={onReloadButtonClick}>
                        <FontAwesomeIcon icon="arrows-rotate" />
                    </button>
                </div>
            ) : (
                <div className="filter-symbol ms-2 me-2" title="Filters">
                    <FontAwesomeIcon icon="filter" />
                </div>
            )}
            <div className="control-btn add-filter me-1">
                <button
                    type="button"
                    className="btn btn-success"
                    title="Add filter"
                    onClick={() => modal.current.open()}>
                    <FontAwesomeIcon icon="square-plus" />
                </button>
            </div>
            {filters.length > 0 && (
                <div className="control-btn clear-filters me-1">
                    <button
                        type="button"
                        className="btn btn-danger"
                        title="Remove all filters"
                        onClick={() => setFilters([])}>
                        <FontAwesomeIcon icon="square-minus" />
                    </button>
                </div>
            )}
            <ul className="filters-list d-flex flex-row">
                {filters.map((f, i) => (
                    <TableFiltersItem
                        key={f.key}
                        filter={f}
                        index={i}
                        onRemove={removeFilter}
                        onOpenEdit={openEditFilter}
                    />
                ))}
            </ul>
            <TableFiltersAddEdit
                ref={modal}
                filterConfigurations={filterConfigurations}
                disableFilters={disabledFilters}
                onAddEdit={addEditFilter}
            />
        </div>
    );
};

export default React.memo(TableFilters);
