import { buildProcedureProperty } from './ProcedureProperty';
import { getCommonFilters, getCommonProperties, getLastCommonProperties } from './BaseProcedureMetadata';
import { isEmpty, isNil } from 'lodash';
import { requestCreateNewMappingRule } from 'api/RepairProcedures/RepairProcedureMappingRuleApi';
import { buildFilteredOemMetadata, buildOemMetadata, OemMetadata } from './BuildOemMetadata';
import { PROPERTY_TYPE } from './PropertyType';
import { OemEngineService, OemMappingRuleBase } from './types';
import { DataSource } from 'components/locations/MappingProcess/Procedures/MappingProceduresTool';

const OEM_METADATA_PROPERTY = 'latestNissanProcedure';

const getRulableProperties = () => [
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.leveL1_NAME',
        displayName: 'Level 1 Name',
        rulePropertyName: 'level1Name',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.leveL2_NAME',
        displayName: 'Level 2 Name',
        rulePropertyName: 'level2Name',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.leveL3_NAME',
        displayName: 'Level 3 Name',
        rulePropertyName: 'level3Name',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.leveL4_NAME',
        displayName: 'Level 4 Name',
        rulePropertyName: 'level4Name',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.leveL5_NAME',
        displayName: 'Level 5 Name',
        rulePropertyName: 'level5Name',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.leveL6_NAME',
        displayName: 'Level 6 Name',
        rulePropertyName: 'level6Name',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.leveL7_NAME',
        displayName: 'Level 7 Name',
        rulePropertyName: 'level7Name',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.siE_TITLE',
        displayName: 'SIE Title',
        rulePropertyName: 'sieTitle',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.searchstring',
        displayName: 'Search String',
        rulePropertyName: 'searchString',
    }),
];

export const getNissanProcedureMetadata = () =>
    buildOemMetadata({
        oemId: 4,
        oemName: 'Nissan',
        properties: [
            ...getCommonProperties(),
            ...getRulableProperties(),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.nissanProcedureId',
                displayName: 'Nissan Procedure Id',
                type: PROPERTY_TYPE.number,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.procedureTitle',
                displayName: 'Title',
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.procedureFileName',
                displayName: 'Procedure File Name',
                enabled: false,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.isDeleted',
                displayName: 'Is Deleted',
                type: PROPERTY_TYPE.bool,
                enabled: false,
                sortable: false,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.manuaL_ID',
                displayName: 'Manual Id',
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.leveL1_ID',
                displayName: 'Level1 Id',
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.leveL2_ID',
                displayName: 'Level2 Id',
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.leveL3_ID',
                displayName: 'Level3 Id',
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.leveL4_ID',
                displayName: 'Level4 Id',
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.leveL5_ID',
                displayName: 'Level5 Id',
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.leveL6_ID',
                displayName: 'Level6 Id',
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.leveL7_ID',
                displayName: 'Level7 Id',
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.giE_ID',
                displayName: 'GIE Id',
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.siE_NUMBER',
                displayName: 'SIE Number',
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.sorT_NO',
                displayName: 'Sort NO',
                type: PROPERTY_TYPE.number,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.siE_FILEPATH',
                displayName: 'SIE Filepath',
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.symptoM_CODE',
                displayName: 'Symptom Code',
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.dtC_CODE',
                displayName: 'DTC Code',
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.leveL1_TYPE',
                displayName: 'Level 1 Type',
                type: PROPERTY_TYPE.number,
                sortable: false,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.lasT_UPDATED_DATE',
                displayName: 'Last updated date',
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.version',
                displayName: 'Version',
                sortable: false,
            }),
            ...getLastCommonProperties(),
        ],
        ruleKey: 'nissanMappingRuleId',
        filters: [...getCommonFilters()],
    });

type NissanMappingRule = OemMappingRuleBase & {
    nissanMappingRuleId: number;
};

class _NissanMappingEngineService implements OemEngineService<NissanMappingRule> {
    metadata: OemMetadata;

    constructor() {
        this.metadata = NISSAN_PROCEDURE_METADATA;
    }

    isRuleValid = (rule: NissanMappingRule): boolean => {
        return (
            (!isNil(rule.typeId) || !isEmpty(rule.groupIds)) &&
            this.metadata.ruleable.some(p => rule[p.rulePropertyName])
        );
    };

    createRule = async (rule: NissanMappingRule): Promise<NissanMappingRule> => {
        const newRule = { ...rule };
        const nissanMappingRuleId = await requestCreateNewMappingRule(this.metadata.oemId, newRule);
        newRule.nissanMappingRuleId = nissanMappingRuleId;

        return newRule;
    };
}

export const NISSAN_PROCEDURE_METADATA = getNissanProcedureMetadata();

export const NISSAN_PROCEDURE_METADATA_SQL = buildFilteredOemMetadata(NISSAN_PROCEDURE_METADATA, DataSource.SQL);
export const NISSAN_PROCEDURE_METADATA_ES = buildFilteredOemMetadata(NISSAN_PROCEDURE_METADATA, DataSource.ES);
export const NissanMappingEngineService = new _NissanMappingEngineService();
