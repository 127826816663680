import { PROPERTY_TYPE } from './PropertyType';
import { buildProcedureProperty } from './ProcedureProperty';
import { MappingRuleId, MappingStatus, VehicleYearList, ProcedureHTML } from 'components/Shared/TableFilters/Types';
import { Group } from 'api/RepairProcedures/types';
import { DataSource } from 'components/locations/MappingProcess/Procedures/MappingProceduresTool';
import { FilterProperty } from './BuildOemMetadata';

export const getCommonProperties = () => [
    buildProcedureProperty({
        name: 'procedureId',
        displayName: 'Rp Procedure Id',
        type: PROPERTY_TYPE.historyView,
    }),
    buildProcedureProperty({
        id: 'firstBookId',
        name: 'booksForProcedure.0.book.bookId',
        displayName: 'Book Id',
        type: PROPERTY_TYPE.bookId,
        sortable: false,
    }),
    buildProcedureProperty({
        id: 'firstBookName',
        name: 'booksForProcedure.0.book.bookName',
        displayName: 'Book name',
        type: PROPERTY_TYPE.bookName,
        sortable: false,
    }),
    buildProcedureProperty({
        name: 'procedureTitle',
        displayName: 'Procedure Title',
        type: PROPERTY_TYPE.procedureView,
    }),
    buildProcedureProperty<Group[]>({
        name: 'stageArea.groups',
        displayName: 'Group Ids',
        multiple: true,
        type: PROPERTY_TYPE.groupsIds,
        sortable: false,
    }),
    buildProcedureProperty<Group[]>({
        id: 'groupsView',
        name: 'stageArea.groups',
        displayName: 'Groups',
        type: PROPERTY_TYPE.groupView,
        sortable: false,
    }),
    buildProcedureProperty({
        id: 'TypeId',
        name: 'stageArea.type.typeId',
        displayName: 'Type Id',
        type: PROPERTY_TYPE.typeId,
        sortable: false,
    }),
    buildProcedureProperty({
        id: 'TypeName',
        name: 'stageArea.type.typeId',
        displayName: 'Type Name',
        type: PROPERTY_TYPE.typeName,
        sortable: false,
    }),
];

export const getLastCommonProperties = () => {
    const commonProperties = [
        buildProcedureProperty({
            id: 'SqlUpdateDate',
            name: 'sqlUpdateDate',
            displayName: 'SQL Update Date',
            type: PROPERTY_TYPE.gmtDate,
            sortable: false,
            searchable: false,
        }),

        buildProcedureProperty({
            id: 'UpdateDate',
            name: 'updateDate',
            displayName: 'ES Index Update Date',
            type: PROPERTY_TYPE.gmtDate,
            sortable: true,
            dataSources: [DataSource.ES],
        }),
        buildProcedureProperty({
            id: '_timestamp',
            name: '_timestamp',
            displayName: 'Upsert Timestamp',
            type: PROPERTY_TYPE.gmtDate,
            sortable: true,
            dataSources: [DataSource.ES],
        }),
    ];

    return commonProperties;
};

export const getCommonFilters = (): FilterProperty[] => {
    const commonFilters = [
        {
            id: 'mappingRuleId',
            label: 'Mapping Rule Id',
            allowInstances: 10,
            property: 'mappingRuleId',
            ruleable: false,
            type: MappingRuleId,
            dataSources: [DataSource.ES, DataSource.SQL],
        },
        {
            id: 'MappingStatus',
            label: 'Procedure Mapping Status',
            allowInstances: 10,
            property: 'MappingStatus',
            ruleable: false,
            type: MappingStatus,
            dataSources: [DataSource.ES, DataSource.SQL],
        },
        {
            id: 'VehicleYear',
            label: 'Vehicle Year',
            allowInstances: 10,
            property: 'VehicleYear',
            ruleable: false,
            type: VehicleYearList,
            dataSources: [DataSource.ES, DataSource.SQL],
        },
        {
            id: 'ProcedureHTML',
            label: 'Procedure HTML',
            allowInstances: 10,
            property: 'ProcedureHTML',
            ruleable: false,
            type: ProcedureHTML,
            dataSources: [DataSource.ES],
        },
    ];

    return commonFilters;
};
