import { buildProcedureProperty } from './ProcedureProperty';
import { buildFilteredOemMetadata, buildOemMetadata, OemMetadata } from './BuildOemMetadata';
import { requestCreateNewMappingRule } from 'api/RepairProcedures/RepairProcedureMappingRuleApi';
import { getCommonFilters, getCommonProperties, getLastCommonProperties } from './BaseProcedureMetadata';
import { isEmpty, isNil } from 'lodash';
import { PROPERTY_TYPE } from './PropertyType';
import { OemEngineService, OemMappingRuleBase } from './types';
import { DataSource } from 'components/locations/MappingProcess/Procedures/MappingProceduresTool';

const OEM_METADATA_PROPERTY = 'latestToyotaProcedure';

const getRulableProperties = () => [
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.tmsMetaLevel1',
        displayName: 'TMS Meta Level1',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'tmsMetaLevel1',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.tmsMetaLevel2',
        displayName: 'TMS Meta Level2',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'tmsMetaLevel2',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.tmsMetaLevel3',
        displayName: 'TMS Meta Level3',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'tmsMetaLevel3',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.title',
        displayName: 'Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'title',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.repairIntelligence1',
        displayName: 'Repair Intelligence 1',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'repairIntelligence1',
    }),
];

export const getToyotaProcedureMetadata = () =>
    buildOemMetadata({
        oemId: 3,
        oemName: 'Toyota',
        properties: [
            ...getCommonProperties(),
            ...getRulableProperties(),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.toyotaProcedureId',
                displayName: 'Toyota Procedure Id',
                type: PROPERTY_TYPE.number,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.oemProcedureId',
                displayName: 'Oem Procedure Id',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.version',
                displayName: 'Version',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.personName',
                displayName: 'Person Name',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.personId',
                displayName: 'Person Id',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.publicationNumber',
                displayName: 'Publication Number',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.appDivision',
                displayName: 'App Division',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.appModelName',
                displayName: 'App Model Name',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.startYear',
                displayName: 'Start Year',
                type: PROPERTY_TYPE.number,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.endYear',
                displayName: 'End Year',
                type: PROPERTY_TYPE.number,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.VDS',
                displayName: 'VDS',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.engineFamily',
                displayName: 'Engine Family',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.audience',
                displayName: 'Audience',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.objectType',
                displayName: 'Object Type',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.metaStructure',
                displayName: 'Meta Structure',
                type: PROPERTY_TYPE.string,
            }),
            buildProcedureProperty({
                name: OEM_METADATA_PROPERTY + '.xmlType',
                displayName: 'Xml Type',
                type: PROPERTY_TYPE.string,
            }),
            ...getLastCommonProperties(),
        ],
        ruleKey: 'toyotaMappingRuleId',
        filters: [...getCommonFilters()],
    });

type ToyotaMappingRule = OemMappingRuleBase & {
    toyotaMappingRuleId: number;
};

class _ToyotaMappingEngineService implements OemEngineService<ToyotaMappingRule> {
    metadata: OemMetadata;

    constructor() {
        this.metadata = TOYOTA_PROCEDURE_METADATA;
    }

    isRuleValid = (rule: ToyotaMappingRule): boolean => {
        return (
            (!isNil(rule.typeId) || !isEmpty(rule.groupIds)) &&
            this.metadata.ruleable.some(p => rule[p.rulePropertyName])
        );
    };

    createRule = async (rule: ToyotaMappingRule): Promise<ToyotaMappingRule> => {
        const newRule = { ...rule };
        const toyotaMappingRuleId = await requestCreateNewMappingRule(this.metadata.oemId, newRule);
        newRule.toyotaMappingRuleId = toyotaMappingRuleId;

        return newRule;
    };
}

export const TOYOTA_PROCEDURE_METADATA = getToyotaProcedureMetadata();

export const TOYOTA_PROCEDURE_METADATA_SQL = buildFilteredOemMetadata(TOYOTA_PROCEDURE_METADATA, DataSource.SQL);
export const TOYOTA_PROCEDURE_METADATA_ES = buildFilteredOemMetadata(TOYOTA_PROCEDURE_METADATA, DataSource.ES);
export const ToyotaMappingEngineService = new _ToyotaMappingEngineService();
