import { FilterProperty, OemMetadataView } from 'hooks/OemModels/MetaModel/BuildOemMetadata';
import { ProcedurePropertyMetadata } from 'hooks/OemModels/MetaModel/ProcedureProperty';

export const mapSearchablePropertiesToFilters = (
    properties: ProcedurePropertyMetadata<unknown, unknown>[]
): FilterProperty[] =>
    properties
        .filter(p => p.searchable)
        .map(c => ({
            id: c.id ? c.id : c.name,
            property: c.name,
            label: c.displayName,
            type: c.type.filterType,
            ruleable: c.ruleable,
            allowInstances: 10,
            dataSources: c.dataSources,
        }));

const buildFiltersConfiguration = (oemMetadata: OemMetadataView): FilterProperty[] => {
    return mapSearchablePropertiesToFilters(
        oemMetadata.metadata.properties.filter(p => oemMetadata.properties.includes(p.id))
    ).concat(oemMetadata.metadata.filters.filter(p => oemMetadata.filters.includes(p.id)));
};

export default buildFiltersConfiguration;
