import { OemMetadataView } from './MetaModel/BuildOemMetadata';
import { OemEngineService } from './MetaModel/types';
import { OemId } from 'helpers/OemId';
import {
    NISSAN_PROCEDURE_METADATA_ES,
    NISSAN_PROCEDURE_METADATA_SQL,
    NissanMappingEngineService,
} from './MetaModel/NissanProcedureMetadata';
import {
    TOYOTA_PROCEDURE_METADATA_ES,
    TOYOTA_PROCEDURE_METADATA_SQL,
    ToyotaMappingEngineService,
} from './MetaModel/ToyotaProcedureMetadata';
import {
    FORD_PROCEDURE_METADATA_ES,
    FORD_PROCEDURE_METADATA_SQL,
    FordMappingEngineService,
} from './MetaModel/FordProcedureMetadata';
import {
    GM_PROCEDURE_METADATA_ES,
    GM_PROCEDURE_METADATA_SQL,
    GMMappingEngineService,
} from './MetaModel/GMProcedureMetadata';
import * as HondaProcedureMetadata from './MetaModel/HondaProcedureMetadata';
import {
    OEMIQ_FORD_PROCEDURE_METADATA_ES,
    OEMIQ_FORD_PROCEDURE_METADATA_SQL,
    OemIqFordMappingEngineService,
} from './MetaModel/OemIqFordProcedureMetadata';
import {
    STELLANTIS_PROCEDURE_METADATA_ES,
    STELLANTIS_PROCEDURE_METADATA_SQL,
    StellantisMappingEngineService,
} from './MetaModel/StellantisProcedureMatadata';
import {
    VW_PROCEDURE_METADATA_ES,
    VW_PROCEDURE_METADATA_SQL,
    VWMappingEngineService,
} from './MetaModel/VWProcedureMetadata';
import {
    SUBARU_PROCEDURE_METADATA_ES,
    SUBARU_PROCEDURE_METADATA_SQL,
    SubaruMappingEngineService,
} from './MetaModel/SubaruProcedureMatadata';
import {
    HYUNDAI_PROCEDURE_METADATA_ES,
    HYUNDAI_PROCEDURE_METADATA_SQL,
    HyundaiMappingEngineService,
} from './MetaModel/HyundaiProcedureMatadata';
import {
    FordProceduresApiService,
    OemIqFordProceduresApiService,
    ToyotaProceduresApiService,
    NissanProceduresApiService,
    GMProceduresApiService,
    HondaProceduresApiService,
    StellantisProceduresApiService,
    VWProceduresApiService,
    SubaruProceduresApiService,
    HyundaiProceduresApiService,
    OemProceduresApiService,
} from 'api/RepairProcedures/OemProceduresApiService';
import { match } from 'ts-pattern';
import { DataSource } from 'components/locations/MappingProcess/Procedures/MappingProceduresTool';
import { useMemo } from 'react';

const oemToConf = (oemId: OemId, dataSource: DataSource) => {
    return match(oemId)
        .with(OemId.Ford, () => ({
            oemMetadataView: dataSource === DataSource.SQL ? FORD_PROCEDURE_METADATA_SQL : FORD_PROCEDURE_METADATA_ES,
            oemEngineService: FordMappingEngineService,
            oemService: FordProceduresApiService,
        }))
        .with(OemId.OEMiQ, () => ({
            oemMetadataView:
                dataSource === DataSource.SQL ? OEMIQ_FORD_PROCEDURE_METADATA_SQL : OEMIQ_FORD_PROCEDURE_METADATA_ES,
            oemEngineService: OemIqFordMappingEngineService,
            oemService: OemIqFordProceduresApiService,
        }))
        .with(OemId.Toyota, () => ({
            oemMetadataView:
                dataSource === DataSource.SQL ? TOYOTA_PROCEDURE_METADATA_SQL : TOYOTA_PROCEDURE_METADATA_ES,
            oemEngineService: ToyotaMappingEngineService,
            oemService: ToyotaProceduresApiService,
        }))
        .with(OemId.Nissan, () => ({
            oemMetadataView:
                dataSource === DataSource.SQL ? NISSAN_PROCEDURE_METADATA_SQL : NISSAN_PROCEDURE_METADATA_ES,
            oemEngineService: NissanMappingEngineService,
            oemService: NissanProceduresApiService,
        }))
        .with(OemId.GMC, () => ({
            oemMetadataView: dataSource === DataSource.SQL ? GM_PROCEDURE_METADATA_SQL : GM_PROCEDURE_METADATA_ES,
            oemEngineService: GMMappingEngineService,
            oemService: GMProceduresApiService,
        }))
        .with(OemId.Honda, () => ({
            oemMetadataView:
                dataSource === DataSource.SQL
                    ? HondaProcedureMetadata.HONDA_PROCEDURE_METADATA_SQL
                    : HondaProcedureMetadata.HONDA_PROCEDURE_METADATA_ES,
            oemEngineService: HondaProcedureMetadata.HondaMappingEngineService,
            oemService: HondaProceduresApiService,
        }))
        .with(OemId.Chrysler, () => ({
            oemMetadataView:
                dataSource === DataSource.SQL ? STELLANTIS_PROCEDURE_METADATA_SQL : STELLANTIS_PROCEDURE_METADATA_ES,
            oemEngineService: StellantisMappingEngineService,
            oemService: StellantisProceduresApiService,
        }))
        .with(OemId.Volkswagen, () => ({
            oemMetadataView: dataSource === DataSource.SQL ? VW_PROCEDURE_METADATA_SQL : VW_PROCEDURE_METADATA_ES,
            oemEngineService: VWMappingEngineService,
            oemService: VWProceduresApiService,
        }))
        .with(OemId.Subaru, () => ({
            oemMetadataView:
                dataSource === DataSource.SQL ? SUBARU_PROCEDURE_METADATA_SQL : SUBARU_PROCEDURE_METADATA_ES,
            oemEngineService: SubaruMappingEngineService,
            oemService: SubaruProceduresApiService,
        }))
        .with(OemId.Hyundai, () => ({
            oemMetadataView:
                dataSource === DataSource.SQL ? HYUNDAI_PROCEDURE_METADATA_SQL : HYUNDAI_PROCEDURE_METADATA_ES,
            oemEngineService: HyundaiMappingEngineService,
            oemService: HyundaiProceduresApiService,
        }))
        .otherwise(() => ({ oemMetadataView: null, oemEngineService: null, oemService: null }));
};

const useOemService = (
    oemId: number | string,
    dataSource = DataSource.SQL
): {
    oemMetadataView: OemMetadataView;
    oemEngineService: OemEngineService<unknown>;
    oemService: OemProceduresApiService<unknown>;
} => {
    const oemIdAsNumber = typeof oemId === 'number' ? oemId : parseInt(oemId, 10);
    return useMemo(() => oemToConf(oemIdAsNumber as OemId, dataSource), [dataSource, oemIdAsNumber]);
};

export default useOemService;
