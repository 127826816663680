import ImportRecord from './ImportRecord';
import useVehicleDataManager from './useVehicleDataManager';
import SmallSpinner from 'components/SmallSpinner';

const VehicleDataManager = () => {
    const { importRecords, isLoading } = useVehicleDataManager();

    return (
        <>
            <div className="p-3">
                <div className="table sticky-header">
                    <div className="col p-0">
                        {isLoading ? (
                            <SmallSpinner className="mt-4" />
                        ) : (
                            <table className="table ">
                                <thead>
                                    <tr>
                                        <th className="text-nowrap" scope="col" data-testid="headerImportHistoryID">
                                            Import ID
                                        </th>
                                        <th className="text-nowrap" scope="col" data-testid="headerStartDate">
                                            Start Date/Time
                                        </th>
                                        <th className="text-nowrap" scope="col" data-testid="headerTrimFiltering">
                                            Status
                                        </th>
                                        <th className="text-nowrap" scope="col" data-testid="headerDuration">
                                            Duration
                                        </th>
                                        <th className="text-nowrap" scope="col" data-testid="headerFileCount">
                                            File Count
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {importRecords.map(r => {
                                        return <ImportRecord key={r.importHistoryId} importRecord={r} />;
                                    })}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default VehicleDataManager;
